import { useEffect } from "react";
import Analytics from "../utils/tracking";

const RedirectTemplate = ({ pageContext }) => {
  const { toPath, trackingEvent } = pageContext;

  useEffect(() => {
    ;(async () => {
      if (trackingEvent) {
        await Analytics.track(trackingEvent)
      }
      window.location.href = toPath;
    })()
  }, [])

  useEffect(() => {
  }, [toPath]);

  return null;
};

export default RedirectTemplate;
